<template>
  <div id="mapContainer">
    <button @click="bdMap()" class="but">还原</button>
  </div>
</template>

<script>
// import { MP } from '@/map.js'
import BMap from 'BMap'
export default {
  data () {
    return {
    }
  },
  mounted () {
    this.bdMap()
    // this.$nextTick(function () {
    //   var _this = this;
    //   MP(_this.ak).then(BMap => {
    //     var map = new BMap.Map("allmap");
    //     var point = new BMap.Point('经纬度', '经纬度');
    //     map.centerAndZoom(point, 19);
    //     var marker = new BMap.Marker(point);  // 创建标注
    //     map.addOverlay(marker);              // 将标注添加到地图中

    //     var label = new BMap.Label("公司名字", { offset: new BMap.Size(20, -10) });
    //     label.setStyle({
    //       borderColor: "black"

    //     });
    //     //marker.addEventListener("click", function(){    //点击弹出可去掉注释      
    //     marker.setLabel(label); //开启信息窗口
    //     //});  //点击弹出可去掉注释         

    //   })
    // })

  },
  methods: {
    bdMap () {
      //创建地图
      let map = new BMap.Map("mapContainer");
      let point = new BMap.Point(113.962257, 22.542256);
      map.centerAndZoom(point, 20); // 创建点坐标
      map.addControl(new BMap.NavigationControl());
      map.enableScrollWheelZoom(true);//允许鼠标滚动缩放
      // 初始化地图， 设置中心点坐标和地图级别
      let marker = new BMap.Marker(point);
      // let label = marker.setLabel(this.setLabelStyle("九州电器大厦"));
      map.addOverlay(marker); //添加覆盖物
      // //添加覆盖物文字
      var spanB = "深圳市美丽范科技有限公司"
      let label = new BMap.Label(spanB, {
        offset: new BMap.Size(10, -35),
        position: point,
      });
      label.setStyle({
        color: "#fff",
        backgroundColor: "#666",
        border: "0",
        fontSize: "14px",
        width: "170px",
        opacity: "0.8",
        verticalAlign: "center",
        borderRadius: "10px 10px 10px 0",
        whiteSpace: "normal",
        wordWrap: "break-word",
        padding: "7px",
      })
      marker.setLabel(label);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>