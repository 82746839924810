<template>
  <div class="about-wrap">
    <vue-nav activeIndex='about'></vue-nav>
    <div class="bl"><img src="../assets/images/aboutbaner.png" alt=""></div>
    <div class="mov">
      <h2 id="introduction">公司介绍</h2>
      <p>深圳美丽范科技有限公司成立于2014年12月，总部设在有"中国硅谷“之称的深圳市南山高新技术产业园区。深圳市美丽范科技公司诞生在移动互联网时代、国家“互联网+”
        战略背景下，是一家致力于为美业提供从互联网规划设计、建设实施、运营维护、到传播推广的“互联网+"全栈服务高新企业，致力于促进中国美业智能化健康可持续发展。
        美业汇移动店务管理软件由拥有30年直营美容院管理运营经验的美业大佬会同原腾讯技术研究院团队历时5年时间倾心打造。软件立足于全方位赋能美容院，通过提升工作效率、管理效率达到顾客、员工、店价值的提升，从而达到辅助美容院实现裂变盈利的目标。
      </p>
      <video controls autoplay name="media" class="opshow"
        src="https://mlf-idc.oss-cn-shenzhen.aliyuncs.com/video/meiyehui.mp4">
      </video>
    </div>
    <div class="about2">
      <div class="two">
        <h2>企业文化</h2>
        <div class="box">
          <span>"</span>
          <strong>品质至上</strong>
          <p>美业汇坚持以品质为根、诚信为本、
            服务至上的理念为宗旨，竭诚打造
            产品的每一个细节，坚守每一步
            服务品质。</p>
        </div>
        <div class="box">
          <span>"</span>
          <strong>求真务实</strong>
          <p>始终坚持以“实践者”精神为行动准则，
            以行践言，以踏实、稳健的脚步开拓前
            进的道路。</p>
        </div>
        <div class="box">
          <span>"</span>
          <strong>责任为魂</strong>
          <p>主动履责，勇于担责，尽职尽责；
            责任是一种义务，责任是一种付出，
            责任更是一种回报,这是我们持续
            发展的最坚强保障。</p>
        </div>
        <div class="box">
          <span>"</span>
          <strong>创新共赢</strong>
          <p>美业汇身处美业店务管理，唯一不变的
            就是变化本身，美业互联网日新月异、
            无论外界风云如何变幻，我们永葆创新精
            神不变。</p>
        </div>
      </div>

    </div>
    <div class="about3">
      <h2 id="course">公司发展历程</h2>
      <img src="../assets/images/fzsss_03.jpg" alt="">
    </div>
    <div class="about4">
      <div class="relame">
        <h2 id="contactUs">联系我们</h2>
        <baidu-map class="left">
        </baidu-map>
        <!-- <div class="left"> <img src="../assets/images/mlfgps.png" alt=""></div> -->
        <div class="right">
          <p>公司地址：深圳市南山区科技南十二路九洲电器大厦5楼</p>
          <p class="o1">联系电话：136-0200-1885</p>
          <div class="right1">
            <img src="../assets/images/qer.png" alt="">
            <p class="isp">关注我们</p>
          </div>
          <div class="right1 anima">
            <p class="ios">ios应用下载</p>
          </div>
          <div class="right1 anima1">
            <p class="and">android下载</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bnav from './components/b-nav'
import baidumap from './components/map'
export default {
  components: {
    'vue-nav': bnav,
    'baidu-map': baidumap
  },
  name: 'index',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }

}
</script>
<style lang="scss" scoped>
@import "../assets/about.scss";
</style>